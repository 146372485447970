<script setup>
import LanguageSelect from "~/components/Shop/Nav/LanguageSelect.vue";
import HorizontalMenuNav from "~/components/Shop/Nav/HorizontalMenuNav.vue";
import TableNumber from "~/components/Shop/Nav/TableNumber.vue";

const drawer = ref(false);
const fixed = ref(false);

const shopStore = useShopStore();
const { shopData, orgInfoModal } = storeToRefs(shopStore);

const productStore = useProductStore();
const { categories } = storeToRefs(productStore);

const orderStore = useOrderStore();
const { toSelection } = orderStore;

const props = defineProps({
  side_bar: {
    required: false,
    default: true,
    type: Boolean,
  },
});

const scrollToElement = (target_id) => {
  const el = document.getElementById("category_" + target_id);
  el.style.scrollMargin = "70px";
  if (el) {
    el.scrollIntoView({ behavior: "smooth" });
  }
};

async function openInfoModal() {
  orgInfoModal.value = true;
}
</script>
<template>
  <v-app>
    <v-navigation-drawer
      v-if="props.side_bar"
      v-model="drawer"
      bottom
      fixed="fixed"
      mobile-breakpoint="md"
      width="200"
      app
    >
      <div class="mt-5 text-body-1 text-center">Menu</div>
      <v-list class="mt-9">
        <v-list-item
          v-for="(item, i) in categories"
          :key="i"
          :value="item"
          color="grey-lighten-1"
          variant="flat"
          @click.native="scrollToElement(item.id)"
        >
          <v-list-item-title v-text="item.name"></v-list-item-title>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-btn
          v-if="shopData"
          @click.prevent="openInfoModal"
          class="text-center mb-4 bg-secondary m-2 rounded-0 w-100"
          variant="text"
        >
          {{ shopData.name }} Info
        </v-btn>
        <div class="px-2 mb-16">
          <language-select></language-select>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar :fixed="fixed" class="elevation-1" app flat>
      <v-app-bar-nav-icon
        v-if="props.side_bar"
        @click.stop="drawer = !drawer"
      />
      <v-spacer></v-spacer>
      <NuxtLink :to="toSelection()">
        <v-row>
          <v-col>
            <v-img
              :width="150"
              aspect-ratio="16/9"
              cover
              :src="'/placeholders/albronxpaytree.png'"
              class=""
            ></v-img>
          </v-col>
        </v-row>
      </NuxtLink>
      <v-spacer></v-spacer>
      <table-number></table-number>
    </v-app-bar>
    <v-main>
      <horizontal-menu-nav v-if="props.side_bar"></horizontal-menu-nav>
      <slot></slot>
    </v-main>
  </v-app>
</template>

<style>
a {
  text-decoration: none;
}
</style>
