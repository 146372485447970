<template>
  <div v-if="cookieTable"></div>
  <v-tooltip location="bottom" :open-on-click="true">
    {{ cookieTable }}
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" variant="outlined" class="pa-2 py-1">
        {{ $t("general.table") }}:
        <div class="truncated-text font-weight-bold">
          {{ truncatedCookieTable }}
        </div>
      </v-btn>
    </template>
  </v-tooltip>
</template>

<script setup>
import { computed } from "vue";

const cookieTable = useCookie("ptp_tn");
const maxLength = 8;

const truncatedCookieTable = computed(() => {
  if (typeof cookieTable.value !== "undefined" && cookieTable.value !== null) {
    if (cookieTable.value.length > maxLength) {
      return cookieTable.value.substring(0, maxLength) + "...";
    }
  }
  return cookieTable.value;
});
</script>

<style scoped>
.truncated-text {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
