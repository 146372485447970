<script lang="ts" setup>
import { Vue3Lottie } from "vue3-lottie";
import closedJSON from "~/resources/lottie_closed.json";

const closedDialog = ref(false);
</script>
<template>
  <v-dialog v-model="closedDialog" fullscreen persistent>
    <v-card class="pa-4">
      <v-card-title class="justify-center align-center">
        <v-img
          :width="150"
          aspect-ratio="16/9"
          :src="'/placeholders/albronxpaytree.png'"
          class="justify-center align-center"
        ></v-img>
      </v-card-title>

      <Vue3Lottie
        :animationData="closedJSON"
        :height="200"
        :width="200"
        :loop="1"
      />
      <div class="text--secondary">
        {{ $t("general.closed") }}
      </div>
    </v-card>
  </v-dialog>
</template>
