<template>
  <v-snackbar
    v-model="placingOrderErrorToastBoolean"
    timeout="3000"
    vertical
    color="red"
  >
    <!--    <p>{{ placingOrderErrorToast }}</p>-->
    <p>{{ $t("errors.email") }}</p>
  </v-snackbar>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";

const orderStore = useOrderStore();
const { placingOrderErrorToast, placingOrderErrorToastBoolean } =
  storeToRefs(orderStore);
</script>
