<script lang="ts" setup>
import { useProductSelectionStore } from "~/stores/productSelection";

const productStore = useProductSelectionStore();
const { addProduct } = productStore;
const { nixDialog, nix, nixProduct } = storeToRefs(productStore);

const agree = () => {
  nix.value = true;
  nixDialog.value = false;

  addProduct(nixProduct.value);
};
</script>
<template>
  <v-dialog v-model="nixDialog" max-width="400" persistent>
    <v-card class="pa-4 text-center justify-center align-center">
      <v-img
        class="text-white"
        width="150"
        src="https://www.trimbos.nl/wp-content/uploads/2020/12/nix18-scaled.jpg"
        cover
      ></v-img>

      <div class="text--primary">
        {{ $t("ticketSelection.nixText") }}
      </div>
      <template v-slot:actions>
        <v-btn variant="outlined" @click="nixDialog = false">
          {{ $t("ticketSelection.nixNo") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn variant="flat" class="bg-secondary" @click="agree">
          {{ $t("ticketSelection.nixYes") }}
        </v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>
