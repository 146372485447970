<script lang="ts" setup>
import { useOrderStore } from "~/stores/order";
import { useShopStore } from "~/stores/shop";

const orderStore = useOrderStore();
const { placingOrderError } = storeToRefs(orderStore);

const shopStore = useShopStore();
const { qrDialog, shopError } = storeToRefs(shopStore);

const error = ref<boolean>(shopError.value ?? false);

const isError = computed(() => placingOrderError.value || shopError.value);

watch(isError, (newValue) => {
  if (newValue) {
    error.value = true;
    qrDialog.value = false;
  } else {
    error.value = false;
    qrDialog.value = true;
  }
});

function refresh() {
  location.reload();
}
</script>
<template>
  <v-dialog v-model="error" max-width="400" class="error-bar" persistent>
    <v-card class="pa-4 text-center justify-center align-center">
      Er ging iets mis!
      <v-btn class="mt-3 bg-primary" @click="refresh"> Refresh </v-btn>
    </v-card>
  </v-dialog>
</template>
<style>
.error-bar {
  z-index: 99999;
}
</style>
